<template>
  <div>
    <vs-prompt
      @close="closeModal"
      :active.sync="memberModal"
      title="Add Member"
      :buttons-hidden="true">
      <VuePerfectScrollbar :is="'div'" class="scroll-area p-4" :settings="settings" >
        <form>
          <form-wizard @on-complete="addChild">
            <tab-content title="Gender" icon="user">
              <div @click="changeGender('BOY')" :class="gender === 'BOY' ? 'selected' : ''" class="mb-4 text-center cursor-pointer border-solid border-2 border-slate-300"> 
                <vs-icon class="self-center" size="90px" color="primary" icon="boy"></vs-icon>
              </div>
              <div @click="changeGender('GIRL')" :class="gender === 'GIRL' ? 'selected' : ''" class="mb-4 text-center cursor-pointer border-solid border-2 border-slate-300"> 
                <vs-icon class="self-center" size="90px" color="warning" icon="girl"></vs-icon>
              </div>
            </tab-content>
            <tab-content title="Personal Info" icon="ti-settings">
              <div class="text-center">
                <img class="ml-auto mr-auto rounded-full w-20 h-20" :src="user.image" />
                <div class="image-upload absolute text-center">
                  <label for="file-input">
                    <vs-icon class="camera-icon cursor-pointer p-1" icon="photo_camera" size="small" color="#fff"></vs-icon>
                  </label>
                  <input id="file-input" type="file" @change="changeImage" ref="fileInput" accept="image/*"/>
                </div>
              </div>
              <div class="mb-6">
                <div class="mb-2">
                  <label class="mb-6 blue">First Name</label>
                </div>
                <div>
                  <vs-input placeholder="First Name" v-model="user.firstName"/>
                </div>
              </div>
              <div class="mb-6">
                <div class="mb-2">
                  <label class="mb-6 blue">Last Name</label>
                </div>
                <div>
                  <vs-input placeholder="Last Name" v-model="user.lastName"/>
                </div>
              </div>
              <div class="mb-6">
                <div class="mb-2">
                  <label class="mb-6 blue">Date Of Birth</label>
                </div>
                <div>
                  <datepicker placeholder="Select Date" v-model="user.date"></datepicker>
                </div>
              </div>
            </tab-content>
            <tab-content title="Last step" icon="ti-check">
              <div v-if="!subInterestView" class="vs-row w-full">
                <p class="text-lg mb-3">Select your interests</p>
                <div class="vs-col xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full p-2" v-for="(item, index) in interests" :key="item.index" :offset="index > 0 ? 2 : 0">
                  <el-card :body-style="{ padding: '0px' }">
                    <div @click="interestDetail(item)" class="cursor-pointer cardBody">
                      <span class="topPicksText">{{item.name}}</span>
                      <img :src="item.photo_name" class="image">
                    </div>
                  </el-card>
                </div>
              </div>
              <div v-else>
                <vs-button class="mb-base" @click="backToInterests()" radius color="dark" type="border" icon="chevron-left"></vs-button>
                <div class="pt-5 pb-40">
                  <div @click="addInterestToMember(item)" :class="interestsChoosed.includes(item.sub_interests_id) ? 'selected-interest' : ''" class="con-vs-chip chip-btn cursor-pointer" v-for="item in subInterests" :key="item.index">
                    <p> {{item.sub_interests_name}}</p>
                  </div>
                </div>
              </div>
            </tab-content>
          </form-wizard>
        </form>
      </VuePerfectScrollbar>
    </vs-prompt>
  </div>
</template>

<script>
import axios from '../../axios.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker'

export default {
  props: ['memberModal'],
  data () {
    return {
      subInterestView: false,
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      subInterests: [],
      user: {
        image: '',
        firstName: '',
        lastName: '',
        date: null
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      data: {},
      interests: [],
      interestsChoosed: [],
      gender: ''
    }
  },
  methods: {
    changeImage () {
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.user.image = URL.createObjectURL(file)
      }
      const formData = new FormData()
      formData.append('file', this.$refs.fileInput.files[0])
    },
    addChild () {
      this.$vs.loading()
      const child = {
        age: new Date(new Date() - new Date(this.user.date)).getFullYear() - 1970,
        gender: this.gender,
        name: this.user.firstName + ',' + this.user.lastName,
        interests_id: [],
        child_dob: new Date(this.user.date)
      }
      this.interestsChoosed.forEach(element => {
        child.interests_id.push({
          interests_id: element
        })
      })
      const formData = new FormData()
      formData.append('photo', this.$refs.fileInput.files[0])
      formData.append('age', child.age)
      formData.append('gender', child.gender)
      formData.append('name', child.name)
      formData.append('interests_id', JSON.stringify(child.interests_id))
      formData.append('dob', child.child_dob)
      axios.post('v1/user/addChild', formData, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.$emit('loadMembers')
          this.$emit('memberModal', false)
          this.$vs.loading.close()
        }
      })
    },
    changeGender (gender) {
      this.gender = gender
    },
    backToInterests () {
      this.subInterestView = false
    },
    addInterestToMember (item) {
      if (!this.interestsChoosed.includes(item.sub_interests_id)) {
        this.interestsChoosed.push(item.sub_interests_id)
      } else {
        var index = this.interestsChoosed.indexOf(item.sub_interests_id);
        if (index > -1) {
          this.interestsChoosed.splice(index, 1);
        }
      }
    },
    loadInterests () {
      this.$vs.loading()
      axios.get('v1/interestsList', {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.interests = response.data.payload
          this.$vs.loading.close()
        }
      })
    },
    closeModal () {
      this.$emit('memberModal', false)
      this.$emit('loadData')
    },
    interestDetail (data) {
      this.subInterestView = true
      this.subInterests = this.interests.filter(item => item.interests_id === data.interests_id)
      this.subInterests = this.subInterests[0].sub_interests
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  created () {
    this.loadInterests()
  }
}
</script>

<style scoped>
.image-upload {
  right: 35%;
  top: 10%;
}
.camera-icon {
  width: 32px !important;
  height: 32px !important;
  background-color: #00DCDC;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.image-upload>input {
  display: none;
}
.selected {
  background: aliceblue;
}
.selected-interest {
  color: white !important;
  background: #F89416 !important;
}
.chip-btn {
  color: #000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px 28px 10px 20px;
  background-color: #fff;
  border-radius: 10px;
  margin: 5px;
  font-size: 13px;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.blue {
  color: #00DCDC;
}
.orange {
  color: #F89416;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color:#00DCDC;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.cardBody .topPicksText {
  color:#000;
  padding: 10px 15px;
  width: 155px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 1.75rem;
  border-top-right-radius: 100px;
  text-align: left;
}
.cardBody .topPicksText:hover {
  color:#fff;
  background-color: #00DCDC;
}
.cardBody .btn:hover {
  background-color: #999;
}
/* .vs-input-primary > .vs-input--input:focus {
  border: 1px solid rgba(5, 218, 220, 1) !important;
}
.vs-input-primary.isFocus .vs-input--label, 
.vs-input-primary .vs-input--input:focus~.icon-inputx, 
.vs-input-primary .vs-input--input:focus~.vs-placeholder-label {
  color: #00DCDC !important;
} */
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #FFF;
  background-color: #F89416 !important;
  /* border-color: #409EFF; */
  box-shadow: -1px 0 0 0 #409eff;
}
</style>


<style>
.wizard-header,
.wizard-nav,
.wizard-progress-with-circle {
  display: none !important;
}
.vue-form-wizard .wizard-tab-content {
  padding: 0px 15px 10px;
}
</style>