<template>
  <div class="pb-96">
    <div class="vs-row hero">
      <div class="hero-text self-center mx-20">
        <p class="text-5xl font-bold pl-4">Manage Members</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>
    <div class="divide-y divide-gray-300 mx-10">
      <div class="vs-row mt-7 pb-24">
        <div class="vs-col sm:w-2/3 w-full">
          <p class="text-lg font-bold">Members</p>
          <p class="text-sm text-gray-500">Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
        </div>
        <div class="vs-col sm:w-1/3 w-full sm:my-0 my-2 flex justify-end">
          <vs-button @click="openMemberModal()" class="btn" color="#F89416" text-color="#fff">Add More Members</vs-button>
        </div>
      </div>
      <div class="vs-row pt-7 w-full">
        <div @click="memberDetails(member)" class="vs-col px-3 xl:w-1/6 lg:w-1/5 md:w-1/4 sm:w-1/3 w-full" v-for="member in members" :key="member.index">
          <vs-card class="cursor-pointer card border text-center">
            <vs-avatar size="70px" :src="member.child_photo"/>
            <p class="text-base">{{member.child_name}}</p>
            <p class="text-sm text-gray-400">{{member.child_age}} Years Old</p>
          </vs-card>
        </div>
      </div>
    </div>
    <add-member :memberModal="memberModal" @loadMembers="loadMembers" @memberModal="memberModal = $event"/>
  </div>
</template>

<script>
import axios from '../axios.js'
import AddMember from './components/AddMember.vue'

export default {
  data() {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'Profile',
          url: 'profile',
        },
        {
          title: 'Manage Members',
          active: true
        }
      ],
      editMemberModal: false,
      members: [],
      memberModal: false
    }
  },
  methods: {
    loadMembers () {
      this.$vs.loading()
      axios.post('v1/user/userChildDetails', {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.members = response.data.payload.child_data
          this.$vs.loading.close()
        }
      })
    },
    memberDetails (member) {
      this.$router.push({
        name: 'member-details',
        params: {
          memberID: member.child_id
        }
      })
    },
    openMemberModal () {
      this.memberModal = true
    }
  },
  components: {
    AddMember
  },
  created () {
    this.loadMembers()
  }
}
</script>

<style scoped>
.hero {
  background-image: url('../assets/images/footer.jpg');
}
.card {
  box-shadow: none;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.btn {
  padding: 5px 15px;
}
</style>